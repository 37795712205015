import ICompany, {
  AmsCompanyInfo,
  CompanyLocationInfo,
  FinnCompanyInfo,
  IndeedCompanyInfo,
  JobindexCompanyInfo,
  DuunitoriCompanyInfo,
  FacebookAndInstagramCompanyInfo,
  AdwayCompanyInfo,
  BesokslivCompanyInfo,
  JobnetCompanyInfo,
} from '../model/company.interface';
import { IEnterprise } from '../model/enterprise.interface';
import { User } from '../model/user.interface';
import { Roles } from '../model/role.interface';
import { TranslationKeys } from '../model/translation-object.interface';

export class Organization {

  id: number;
  guid: string;
  name: string;
  domain: string;
  logo: string;
  primaryColor: string;
  useWhyWorkWithUs?: boolean;
  useLandingPage?: boolean;
  landingPage: {id: number};
  whyWorkWithUs: {id: number};
  authenticationType: string;
  allow2faTotpAuthentication: boolean;
  allow2faEmailAuthentication: boolean;
  useUniversalJob: boolean;
  useIntroText: boolean;
  introText: {id: number};

  constructor() { }
}

export class Company extends Organization {

  active: boolean;
  address: string;
  adwayCompany: AdwayCompanyInfo;
  amsCompany: AmsCompanyInfo;
  allowAnonymousFlow: boolean;
  candidatePortalLanguage: TranslationKeys;
  customerPortalLanguage: TranslationKeys;
  email: string;
  finnCompany: FinnCompanyInfo;
  indeedCompany: IndeedCompanyInfo;
  jobindexCompany: JobindexCompanyInfo;
  duunitoriCompany: DuunitoriCompanyInfo;
  facebookAndInstagramCompany: FacebookAndInstagramCompanyInfo;
  instagramProfileUrl: string;
  language: TranslationKeys;
  linkedInProfileUrl: string;
  facebookProfileUrl: string;
  location: CompanyLocationInfo;
  organizationNumber: string;
  postalCode: string;
  smsTitle: string;
  termsAndConditions: string;
  defaultTermsAndConditions: boolean;
  vatNumber: string;
  integratedWithTilTid: boolean;
  integratedWithRefapp: boolean;
  integratedWithSri: boolean;
  integratedWithAlva: boolean;
  isAllowedVideoInterview: boolean;
  isPredefinedBusinessQuestionFeatureAllowed: boolean;
  isRequisitionFormFeatureAllowed?: boolean;
  universalJob: { id: number };
  termsAndConditionsStatus: string;
  interviewTermsAndConditions?: string;
  link: string;
  enterprise: { id: number };
  removedJobPicturesFromCompanyLibrary: string[];
  removedJobVideosFromCompanyLibrary: string[];
  candidateUniversalJobReminderTimeframe: number;
  companyWebsiteLinkButtonName: string;
  besokslivCompany: BesokslivCompanyInfo;
  jobnetCompany: JobnetCompanyInfo;
  allowedWhistleBlowerAlert: boolean;
  isAllowedAgreementsFeature: boolean;
  isAllowedAiFeature: boolean;

  constructor(company: ICompany) {
    super();

    this.active = company.active;
    this.address = company.address;
    this.adwayCompany = company.adwayCompany;
    this.amsCompany = company.amsCompany;
    this.allowAnonymousFlow = !!company.allowAnonymousFlow;
    this.candidatePortalLanguage = company.candidatePortalLanguage;
    this.customerPortalLanguage = company.customerPortalLanguage;
    this.email = company.email;
    this.facebookProfileUrl = company.facebookProfileUrl;
    this.finnCompany = company.finnCompany;
    this.guid = company.guid;
    this.id = company.id;
    this.indeedCompany = company.indeedCompany;
    this.jobindexCompany = company.jobindexCompany;
    this.duunitoriCompany = company.duunitoriCompany;
    this.facebookAndInstagramCompany = company.facebookAndInstagramCompany;
    this.instagramProfileUrl = company.instagramProfileUrl;
    this.language = company.language;
    this.linkedInProfileUrl = company.linkedInProfileUrl;
    this.location = company.location;
    this.name = company.name;
    this.organizationNumber = company.organizationNumber;
    this.postalCode = company.postalCode;
    this.primaryColor = company.primaryColor;
    this.smsTitle = company.smsTitle;
    this.termsAndConditions = company.termsAndConditions;
    this.defaultTermsAndConditions = company.defaultTermsAndConditions;
    this.interviewTermsAndConditions = company.interviewTermsAndConditions;
    this.vatNumber = company.vatNumber;
    this.integratedWithTilTid = company.integratedWithTilTid;
    this.integratedWithSri = company.integratedWithSri;
    this.isAllowedVideoInterview = company.isAllowedVideoInterview;
    this.isPredefinedBusinessQuestionFeatureAllowed = company.isPredefinedBusinessQuestionFeatureAllowed;
    this.isRequisitionFormFeatureAllowed = company.isRequisitionFormFeatureAllowed;
    this.universalJob = company.universalJob;
    this.useLandingPage = company.useCompanyLandingPage;
    this.useUniversalJob = company.useUniversalJob;
    this.whyWorkWithUs = company.whyWorkWithUs;
    this.useWhyWorkWithUs = company.useWhyWorkWithUs;
    this.domain = company.companyDomain;
    this.landingPage = company.companyLandingPage;
    this.logo = company.companyLogo;
    this.termsAndConditionsStatus = company.termsAndConditionsStatus;
    this.link = company.link;
    this.enterprise = company.enterprise;
    this.integratedWithRefapp = company.integratedWithRefapp;
    this.authenticationType = company.authenticationType;
    this.allow2faTotpAuthentication = company.allow2faTotpAuthenticationForEmployees;
    this.allow2faEmailAuthentication = company.allow2faEmailAuthenticationForEmployees;
    this.removedJobPicturesFromCompanyLibrary = company.removedJobPicturesFromCompanyLibrary;
    this.removedJobVideosFromCompanyLibrary = company.removedJobVideosFromCompanyLibrary;
    this.candidateUniversalJobReminderTimeframe = company.candidateUniversalJobReminderTimeframe;
    this.useIntroText = company.useCompanyIntro;
    this.introText = company.companyIntro;
    this.companyWebsiteLinkButtonName = company.companyWebsiteLinkButtonName;
    this.besokslivCompany = company.besokslivCompany;
    this.integratedWithAlva = company.integratedWithAlva;
    this.jobnetCompany = company.jobnetCompany;
    this.allowedWhistleBlowerAlert = company.allowedWhistleBlowerAlert;
    this.isAllowedAgreementsFeature = company.isAllowedAgreementsFeature;
    this.isAllowedAiFeature = company.isAllowedAiFeature;
  }
}

export class Enterprise extends Organization {

  companies: ICompany[] = [];

  constructor(enterprise: IEnterprise, user: User) {
    super();

    this.id = enterprise.id;
    this.name = enterprise.name;
    this.domain = enterprise.enterpriseDomain;
    this.logo = enterprise.enterpriseLogo;
    this.primaryColor = enterprise.primaryColor;
    this.guid = enterprise.guid;
    this.useWhyWorkWithUs = enterprise.useEnterpriseWhyWorkWithUs;
    this.useLandingPage = enterprise.useEnterpriseLandingPage;
    this.landingPage = enterprise.enterpriseLandingPage;
    this.whyWorkWithUs = enterprise.enterpriseWhyWorkWithUs;
    this.authenticationType = enterprise.authenticationType;
    this.allow2faTotpAuthentication = enterprise.allow2faTotpAuthenticationForEmployees;
    this.allow2faEmailAuthentication = enterprise.allow2faEmailAuthenticationForEmployees;
    this.useIntroText = enterprise.useEnterpriseIntro;
    this.introText = enterprise.enterpriseIntro;

    const companies = user.role === Roles.enterpriseRM
      ? user.companies
      : enterprise.companies;

    let useUniversalJob = false;

    companies
      .forEach((company: ICompany) => {
        if (company.active) {
          this.companies.push(company);
        }

        useUniversalJob = useUniversalJob || company.useUniversalJob;
      });

    this.useUniversalJob = useUniversalJob;
  }
}
