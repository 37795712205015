export interface Role {
  value: string;
  name: string;
}

export enum Roles {
  enterpriseHR = 'ROLE_ENTERPRISE_HR',
  enterpriseRM = 'ROLE_ENTERPRISE_REGIONAL_MANAGER',
  companyHR    = 'ROLE_HR',
  companyRM    = 'ROLE_REGIONAL_MANAGER',
  recruiter    = 'ROLE_RECRUITER',
  admin        = 'ROLE_ADMIN',
  adminSales   = 'ROLE_ADMIN_SALES'
}
