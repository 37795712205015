export const AMS_FIELDS_TO_DISABLE = [
  'amsMunicipality',
  'amsTimeExtent',
  'amsDuration',
  'amsSalary',
  'experienceRequired',
  'amsContinent',
  'amsJobLocationCountry'
];

export const AMS_FIELDS_EXPERIENCE_REQUIRED = [
  'amsDrivingLicences',
  'qualificationsSummary',
  'preferredQualifications',
  'candidateMustOwnCar'
];

export const BLOCKET_FIELDS_TO_DISABLE = [
  'blocketRegion',
  'blocketMunicipality',
  'blocketTimeExtent',
  'blocketTypeOfEmployment',
  'costCenter'
];

export const FINN_FIELDS_TO_DISABLE = [
  'norwegianZipCode',
  'finnSector',
  'finnDuration',
  'finnIndustries',
  'finnTimeExtent',
  'costCenter',
  'finnRemoteOptions',
  'finnWorkingLanguages'
];

export const INDEED_FIELDS_TO_DISABLE = [
  'indeedLocation',
  'indeedTypeOfEmployment',
  'costCenter'
];

export const NAV_FIELDS_TO_DISABLE = [
  'norwegianZipCode',
  'navEngagementType',
  'navExtent',
  'navJobArrangement',
  'navRemote',
  'navSector',
  'navWorkDay',
  'navWorkHours',
  'positions',
  'navLocation'
];

export const JOBINDEX_FIELDS_TO_DISABLE = [
  'jobindexLocation',
  'jobindexTypeOfEmployment',
  'costCenter'
];

export const DUUNITORI_FIELDS_TO_DISABLE = [
  'duunitoriLocation',
  'duunitoriTypeOfEmployment',
  'costCenter'
];

export const FACEBOOK_AND_INSTAGRAM_FIELDS_TO_DISABLE = [
  'primaryLocation',
  'costCenter',
  'jobCategory',
  'secondaryLocation',
  'flexibleRemoteJob',
  'remoteJob',
  'partTimeJob',
  'temporaryJob',
  'selectedPricePackage',
  'video',
  'image',
  'storyVideo',
  'storyImage'
];

export const VERKSTADSJOBB_FIELDS_TO_DISABLE = [
  'verkstadsjobbLocation',
  'verkstadsjobbBrands'
];

export const ADWAY_FIELDS_TO_DISABLE = [
  'adwayLocation',
  'adwayLocationAlternative',
  'costCenter',
  'video',
  'picture',
  'storyVideo',
  'storyPicture',
  'boost',
  'isPartTime',
  'isRemote',
  'isFlexibleRemote',
  'isTemporary',
];

export const BESOKSLIV_FIELDS_TO_DISABLE = [
  'besokslivLocation',
  'costCenter',
];

export const JOBNET_FIELDS_TO_DISABLE = [

];

export const TIKTOK_FIELDS_TO_DISABLE = [
  'costCenter',
  'tiktokTitle',
  'selectedPricePackage'
];

export const LINKEDIN_FIELDS_TO_DISABLE = [
  'costCenter',
  'location',
  'jobCategory',
  'video',
  'image'
];

export const HIGHER_FIELDS_TO_DISABLE = [
  'costCenter',
  'video',
  'image'
];
