export const TEMPLATE_FIELDS_TO_DISABLE = [
  'applicationDate',
  'branch',
  'jobLocation',
  'jobWorkLocation',
  'responsiblePerson',
  'typeOfEmployment',
  'typeOfEmploymentAddition',
  'responsibleEnterpriseManager',
  'responsibleCustomer',
  'hiddenFromEnterpriseManagers',
  'hiddenFromCustomers',
  'hiddenFromUsers',
  'linkedinProfile',
  'allowAnonymousFlow',
  'activationDateTime',
  'selectedScheduleDate',
  'selectedScheduleTime'
];
