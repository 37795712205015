import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

export function compareHighestAndLowestWeeklyWorkingHoursValidator(form: FormGroup): ValidationErrors | null {
  const highestWeeklyWorkingHours = form.get('highestWeeklyWorkingHours').value;
  const lowestWeeklyWorkingHours = form.get('lowestWeeklyWorkingHours').value;

  return highestWeeklyWorkingHours && lowestWeeklyWorkingHours && highestWeeklyWorkingHours < lowestWeeklyWorkingHours
    ? { workingHoursMissmatch: true }
    : null;
}

export function comparejobnetJobAdTypeAndjobnetJobAdPropertyType(): ValidationErrors | null {
  return { jobAddAndJobAdPropertyMissmatch: true };
}

export function checkIsStreetIsNumber(control: AbstractControl): any {
  const street = control.value;
  return isNaN(street) === false
    ? { streetIsNumber: true }
    : null;
}
