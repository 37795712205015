export const SMS_CONTENT: SmsContent = {
  sv: {
    reminderSms:
      'Hej, Kul att du påbörjat din ansökan, fullfölj alla delar även video-delen för en komplett ansökan. Jag hoppas att vi ses!',
    thankYouMessage: 'Tack',
    thankYouMainMessage: 'Din ansökan har nu framgångsrikt blivit inskickad. Vi hör av oss, lycka till!',
    introMessage: 'Ansökningsprocess',
    // eslint-disable-next-line max-len
    introMainMessage: 'Hej! Kul att du är intresserad av oss. Nedan ser du de olika stegen. Det tar bara några minuter att slutföra ansökan.\n\nSå fort du är klar med din ansökan kommer resultatet på testet. Lycka till!'
  },
  en: {
    reminderSms:
      'Hi, Glad you started your application, complete all parts including the video part for one complete application. I hope to see you!',
    thankYouMessage: 'Thanks',
    thankYouMainMessage: 'Your application has now been successfully submitted. We will contact you, good luck!',
    introMessage: 'Application process',
    // eslint-disable-next-line max-len
    introMainMessage: 'Hi! Great that you are interested in our company. Below you will see the different steps. It will only take approximately 7 minutes to complete the application.\n\nYou will receive instant result on your test upon completed application - Good luck!'
  },
  no: {
    reminderSms:
    'Hei! Så gøy at du skøker jobb hos oss, fullfør alle delene inkludert videodelen for en komplett søknad, vi håper vi ses',
    thankYouMessage: 'Takk',
    thankYouMainMessage: 'Søknaden din er nå sendt inn. Hvis det oppstår en tjeneste som vi tror passer deg, tar vi kontakt!',
    introMessage: 'Søknadsprosess',
    // eslint-disable-next-line max-len
    introMainMessage: 'Hei! Hyggelig at du er interessert i selskapet vårt. Nedenfor ser du de ulike trinnene. Det tar bare noen minutter å fullføre søknaden. Så snart du er ferdig med søknaden din, vil du motta resultatet av testen - Lykke til!'
  },
  de: {
    reminderSms:
      'Guten Tag, bitte füllen Sie die Bewerbung einschließlich des Videoteils aus, um die Bewerbung abzuschließen.',
    thankYouMessage: 'Vielen Dank',
    thankYouMainMessage:
      'Ihre Bewerbung wurde nun erfolgreich eingereicht. Wir werden uns mit Ihnen in Verbindung setzen und wünschen viel Glück!',
    introMessage: 'Bewerbungsprozess',
    // eslint-disable-next-line max-len
    introMainMessage: 'Guten Tag! Wir freuen uns sehr, dass Sie an unserem Unternehmen interessiert sind. Unten sehen Sie die einzelnen Schritte. Die Fertigstellung der Bewerbung dauert nur etwa 7 Minuten.\n\nNach einer abgeschlossenen Bewerbung erhalten Sie sofort Ihr Testergebnis - viel Glück!'
  },
  da: {
    reminderSms:
      'Hej! Godt, at du har påbegyndt din ansøgning. Gennemfør alle dele, inkl. videoen-delen, for at oprette en komplet ansøgning.',
    thankYouMessage: 'Tak',
    thankYouMainMessage: 'Din ansøgning er sendt. Vi kontakter dig, held og lykke!',
    introMessage: 'Ansøgningsproces',
    // eslint-disable-next-line max-len
    introMainMessage: 'Hej! Det glæder os, at du er interesseret i vores virksomhed. Nedenfor ser du de forskellige trin. Det tager kun cirka 7 minutter at gennemføre ansøgningen.\n\nSå snart du er færdig med ansøgningen, modtager du resultatet af din test. Held og lykke!'
  },
  fi: {
    reminderSms:
      'Hei, Hienoa, että aloitit hakemuksesi täyttämisen. Täytä hakemusta varten kaikki osat, mukaan lukien video. ',
    thankYouMessage: 'Kiitos',
    thankYouMainMessage: 'Hakemuksesi on nyt lähetetty onnistuneesti. Otamme sinuun yhteyttä, onnea matkaan!',
    introMessage: 'Hakuprosessi',
    // eslint-disable-next-line max-len
    introMainMessage: 'Hei! Hienoa, että olet kiinnostunut yrityksestämme. Alla näet eri vaiheet. Hakemuksen täyttäminen vie vain noin 7 minuuttia.\n\nSaat testituloksen heti hakemuksen täyttämisen jälkeen - Onnea matkaan!'
  },
};

interface SmsContent {
  [key: string]: {
    reminderSms: string;
    thankYouMessage: string;
    thankYouMainMessage: string;
    introMessage: string;
    introMainMessage: string;
  };
}
