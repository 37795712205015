interface InhouseQuestionContent {
  [key: string]: {
    text: string;
    text2?: string;
    yes: string;
    no: string;
  }
}

export const INHOUSE_QUESTION_CONTENT: InhouseQuestionContent = {
  en: {
    text: 'Are you currently working for',
    yes: 'Yes',
    no: 'No'
  },
  sv: {
    text: 'Arbetar du för närvarande för',
    yes: 'Ja',
    no: 'Nej'
  },
  no: {
    text: 'Arbeider du for øyeblikket for',
    yes: 'Ja',
    no: 'Nei'
  },
  de: {
    text: 'Arbeiten Sie derzeit für',
    yes: 'Ja',
    no: 'Nein'
  },
  da: {
    text: 'Arbeider du for',
    text2: 'i øjeblikket',
    yes: 'Ja',
    no: 'Nej'
  },
  fi: {
    text: 'Työskenteletkö tällä hetkellä yrityksessä',
    yes: 'Kyllä',
    no: 'Ei'
  }
};
