import { RequisitionDinamicControl, IRequisition, RequisitionComments, IRequisitionComments } from '../model/requisition.model';
import { UserCommunicator } from '../model/user.interface';

export class RequisitionClass {
  comment?: string;
  dinamicForm: RequisitionDinamicControl[];
  title: string;
  status?: string;
  createdAt?: Date;
  approvedBy?: UserCommunicator | string;
  creator?: UserCommunicator | string;
  lastDeniedBy?: UserCommunicator | string;
  completelyDeniedBy?: UserCommunicator | string;
  requisitionComments?: RequisitionComments[];
  platforms?: number[];
  id?: number;
  utcOfApproval?: string;


  constructor(requisition: IRequisition, companyName?: string) {

    const requisitionComments: RequisitionComments[] = [];
    if (requisition.requisitionComments) {

      requisition.requisitionComments.reverse();

      requisition.requisitionComments.forEach((comment: IRequisitionComments) => {

        const requisitionComment: RequisitionComments = {
          creatorName: comment.creatorCustomer?.name || comment.creatorEnterpriseManager?.name,
          createdAt: comment.createdAt,
          anonymousCreator: comment.anonymousCustomer || comment.anonymousEnterpriseManager,
          content: comment.content
        };

        requisitionComments.push(requisitionComment);
      });
    }


    let platformsIds: number[] = [];

    if (requisition.allowedJobPlatforms?.length) {
      platformsIds = requisition.allowedJobPlatforms.map((platform) => platform.id);
    }

    if (requisition.publishOnHigher) {
      platformsIds = [...platformsIds, 0];
    }

    this.id = requisition.id;
    this.title = requisition.requisitionTitle;
    this.dinamicForm = requisition.filledInRequisitionFormElements;
    this.status = requisition.requisitionStatus;
    this.createdAt = requisition.createdAt;
    this.requisitionComments = requisitionComments;
    this.platforms = platformsIds;
    this.utcOfApproval = requisition.utcOfApproval;

    const usersCommunicators = ['creator', 'approvedBy', 'lastDeniedBy', 'completelyDeniedBy'];

    usersCommunicators.forEach((prop: string) => {
    this[prop] =  requisition[`${prop}Customer`] || requisition[`${prop}EnterpriseManager`];
      if (this[prop]) {
        this[prop].anonymous = this[prop].anonymousCustomer || this[prop].anonymousEnterpriseManager;
        this[prop] = this[prop].anonymous ? companyName : this[prop].name;
      }
    });
  }

  static prepareForCreate(requisitionClass: RequisitionClass, requisitionFormId: number): IRequisition {

    const requisition: IRequisition = {
      filledInRequisitionFormElements: requisitionClass.dinamicForm,
      requisitionForm: requisitionFormId
    };

    if (requisitionClass.comment) {
      requisition.requisitionCommentContent = requisitionClass.comment;
    }

    return requisition;
  }

  static prepareForCorrecting(requisitionClass: RequisitionClass): IRequisition {

    const requisition: IRequisition = {
      correctedFilledInRequisitionFormElements: requisitionClass.dinamicForm,
      context: 'correcting'
    };

    if (requisitionClass.comment) {
      requisition.requisitionCommentContent = requisitionClass.comment;
    }

    return requisition;
  }

  static prepareForReviewing(requisitionClass: RequisitionClass, requisitionStatus: string): IRequisition {

    const requisition: IRequisition = {
      context: 'reviewing',
      requisitionStatus: requisitionStatus,
    };

    if (requisitionClass.platforms && requisitionClass.platforms.includes(0)) {
      requisition.publishOnHigher = true;
      const index = requisitionClass.platforms.indexOf(0);
      if (index > -1) {
        requisitionClass.platforms.splice(index, 1);
      }
    } else {
      requisition.publishOnHigher = false;
    }

    requisition.allowedJobPlatforms = requisitionClass.platforms;

    if (requisitionClass.comment) {
      requisition.requisitionCommentContent = requisitionClass.comment;
    }

    return requisition;
  }
}
