interface VideoIntro {
  [key: string]: {
    videoMessage: string;
    videoMainMessage: string;
  }
}

export const maxVideoMessageLength = 100;
export const maxVideoMainMessageLength = 500;

export const VIDEO_INTRO_CONTENT: VideoIntro = {
  sv: {
    videoMessage: 'Grattis!',
    // eslint-disable-next-line max-len
    videoMainMessage: 'Du är en av dem som gått vidare! Nu är du nästan klar med din ansökan, du ska nu svara på tre korta videofrågor. Tänk inte på inspelningskvalitén på videon vi är bara nyfikna på dig. När din ansökan är klar kan du se dina testresultat. Lycka till!'
  },
  en: {
    videoMessage: 'Congratulations!',
    // eslint-disable-next-line max-len
    videoMainMessage: 'You are one of those who have moved on to next step! Now you are almost done with your application, you should now answer three short video questions. Do not think about the recording quality of the video we are just curious about you. When your application is ready, you can see your test results. Good luck!'
  },
  no: {
    videoMessage: 'Gratulerer!',
    // eslint-disable-next-line max-len
    videoMainMessage: 'Du er en av dem som har gått videre! Nå er du nesten ferdig med søknaden din, du skal nå svare på tre korte videospørsmål. Ikke tenk på opptakskvaliteten til videoen, vi er bare nysgjerrige på deg. Når søknaden din er klar, kan du se testresultatene dine. Lykke til!'
  },
  de: {
    videoMessage: 'Herzlichen Glückwunsch!',
    // eslint-disable-next-line max-len
    videoMainMessage: 'Sie sind einer von denen, die zum nächsten Schritt übergegangen sind! Nachdem Sie Ihre Bewerbung nun fast abgeschlossen haben, sollten Sie nun drei kurze Videofragen beantworten. Denken Sie nicht über die Aufnahmequalität des Videos nach, wir sind nur neugierig auf Sie. Wenn Ihre Bewerbung fertig ist, können Sie Ihre Testergebnisse sehen. Viel Glück!'
  },
  da: {
    videoMessage: 'Tillykke!',
    // eslint-disable-next-line max-len
    videoMainMessage: 'Du er en af dem, der er gået videre til næste trin! Nu er du næsten færdig med din ansøgning, du skal nu besvare tre korte videospørgsmål. Tænk ikke på optagekvaliteten af videoen, vi er bare nysgerrige på dig. Når din ansøgning er klar, kan du se dine testresultater. Held og lykke!'
  },
  fi: {
    videoMessage: 'Onnittelut!',
    // eslint-disable-next-line max-len
    videoMainMessage: 'Olet yksi niistä, jotka ovat siirtyneet seuraavaan vaiheeseen! Nyt olet melkein valmis hakemuksesi käsittelyyn, sinun pitäisi nyt vastata kolmeen lyhyeen videokysymykseen. Älä ajattele videon tallennuslaatua, olemme vain uteliaita sinusta. Kun hakemuksesi on valmis, näet testitulokset. Onnea!'
  }
};
