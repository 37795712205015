import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { convertStringDateToJSDate, dateDiffInDays } from '../resources/shared-functions';

export function correctActivDate(control: AbstractControl): any {
  const date = control.get('applicationDate').value;
  const status = control.get('status').value;

  if (date) {
    const today = new Date();
    const date2 = convertStringDateToJSDate(date);
    return dateDiffInDays(date2, today) > 0 && status === 'active'
      ? { incorrectActivDate: true }
      : null;
  }
}

export function correctApplicationDate(control: AbstractControl): any {
  const date = control.value;
  if (date) {
    const today = new Date();
    const date2 = convertStringDateToJSDate(date);
    return dateDiffInDays(date2, today) > 0
      ? { incorrectApplicationDate: true }
      : null;
  }
}

export function correctActivationDateTime(control: AbstractControl): ValidationErrors | null {
  const appDate = control.get('applicationDate').value;
  const actDate = control.get('activationDateTime').value;

  if (appDate && actDate) {
    const applicationDate = convertStringDateToJSDate(appDate);
    const activationDateTime = new Date(actDate.replace(' ', 'T').replace('UTC', ':00.000Z'));
    return activationDateTime >= applicationDate
    ? { activationDateAfterLastApplicationDateError: true}
    : new Date() > activationDateTime
      ? { incorrectActivationDateTime: true }
      : null;
  }
}
