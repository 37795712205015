// eslint-disable-next-line max-len
export const gsmCharsetValidationRegex = /^[A-Za-z0-9 \r\n@£$¥èéùìòÇØøÅå\x{0394}_\x{03A6}\x{0393}\x{039B}\x{03A9}\x{03A0}\x{03A8}\x{03A3}\x{0398}\x{039E}ÆæßÉ!"#$%&amp;'()*+,\-.\/:;&lt;=&gt;?¡ÄÖÑÜ§¿äöñüà^{}\\\[~\]|\x{20AC}]*$/;
export const blankCharacterRegex = /^\s*$/;
// eslint-disable-next-line max-len
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"\#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~])[A-Za-z\d!""\#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]{8,128}$/;
export const AMS_NUMBER_REG_EX = /^[0-9]{8}$/;
export const SWEDISH_SSN_REGEX = /^(19|20)\d{2}(01|02|03|04|05|06|07|08|09|10|11|12)((0[1-9])|(1|2)[0-9]|(30|31))-\d{4}$/;
// eslint-disable-next-line max-len
export const URL_REGEX = new RegExp('^(https?:\\/\\/)((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$', 'i');


export enum CountryCodes {
  sweden = 'sv',
  norway = 'no',
  usa = 'en',
  denmark = 'de',
  germany = 'da',
  finland = 'fi'
}

export type CountryRegexes = {
  [key in CountryCodes]: CountryRegex;
};

export interface CountryRegex {
  vatNumber: RegExp;
  orgNumber: RegExp;
  postalCode: RegExp;
}

export const COUNTRY_SPECIFIC_REGEXES: CountryRegexes = {
  sv: {
    vatNumber: /^[S][E]\d{12}$/,
    orgNumber: /^(\s)?[0-9]{2}-[0-9]{6}-[0-9]{4}(\s)?$/,
    postalCode: /^\d{5}$/
  },
  no: {
    vatNumber: /^\d{9}MVA$/,
    orgNumber: /^\d{9}$/,
    postalCode: /^\d{4}$/
  },
  en: {
    vatNumber: /^[\d\D]*$/,
    orgNumber: /^[\d\D]*$/,
    postalCode: /^[\d\D]*$/
  },
  de: {
    vatNumber: /^[\d\D]*$/,
    orgNumber: /^[\d\D]*$/,
    postalCode: /^[\d\D]*$/
  },
  da: {
    vatNumber: /^[\d\D]*$/,
    orgNumber: /^[\d\D]*$/,
    postalCode: /^[\d\D]*$/
  },
  fi: {
    vatNumber: /^[\d\D]*$/,
    orgNumber: /^[\d\D]*$/,
    postalCode: /^[\d\D]*$/
  }
};
