import { CreateSms } from './sms.interface';
import { CreateEmail } from './email.interface';
import { TilTidExport } from './til-tid.interface';
import { PerformActionComponents } from './perform-action.interface';
import { BaseHiringStatus } from './job.interface';
import { HiringStatus } from '../classes/hiring-status.class';
import { AlvaLabsData } from './alva-labs.interface';

export enum TriggerActionTimeTypes {
  relativeTime = 'relativeActionTime',
  timeOfWeek = 'actionTimeOfWeek'
}

export interface Trigger {
  id?: number;
  actionType: PerformActionComponents;
  actionTimeType?: TriggerActionTimeTypes;
  applicationHiringStatus?: BaseHiringStatus | number;
  smsContent?: CreateSms;
  emailContent?: CreateEmail;
  relativeActionTime?: TriggerRelativeActionTime;
  actionTimeOfWeek?: TriggerActionTimeOfWeek;
  exportedTilTidEmployeeSetting?: TilTidExport;
  nextHiringStatus?: BaseHiringStatus | number;
  nextHiringStatusDelayInMinutes?: number;
  refappConfiguration?: null;
  alvaLabsData?: AlvaLabsData;
}

export interface TriggerRelativeActionTime {
  day: number;
  hour: number;
}

export interface TriggerActionTimeOfWeek {
  dayOfWeek: Day;
  hour: number;
  minute: number;
  timeZone: string;
}

export interface TriggerValue {
  relativeActionTime: TriggerRelativeActionTime;
  actionTimeOfWeek: TriggerActionTimeOfWeek;
  actionTimeType: TriggerActionTimeTypes;
  nextHiringStatus?: HiringStatus;
  nextHiringStatusDelayInMinutes?: number
}

export type Day = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
export const DAYS: Day[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export const JOB_TRIGGER_FIELDS = new Map<string, string>([
  [
    'sendEmail',
    'emailContent'
  ],
  [
    'sendSms',
    'smsContent'
  ],
  [
    'exportToTilTid',
    'exportedTilTidEmployeeSetting'
  ],
  [
    'exportToAlva',
    'alvaLabsData'
  ]
]);
