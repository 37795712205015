import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { QUIZ_MODULES } from '../resources/quiz-modules';

export function correctAnswerValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const question = control as FormGroup;
  const correct = (question.get('answers') as FormArray).controls
    .some(answer => {
      const ctrl = answer.get('correct') || answer.get('followUpQuestion') || answer.get('desirability');

      return !!ctrl.value;
    });

  return correct ? null : { noCorrectAnswers: true };
}

export function answerDesirabilityValidator(
  control: FormGroup
): { [key: string]: boolean } | null {
  const correct = (control.get('answers') as FormArray).controls
    .find(answer => answer.get('desirability')?.value === null && answer.get('followUpQuestion')?.value === null);

  return correct ? { populateAllAnswers: true } : null;
}

export function maxNumberOfAnswersCandidateCanChooseValidator(
  control: FormGroup
): { [key: string]: boolean } | null {
  const maxNumber = control.get('maxNumberOfAnswersCandidateCanChoose').value;

  const numberOfAnswersWithMaxDesirability =
    (control.get('answers') as FormArray).controls.filter(answer => answer.get('desirability')?.value === 3).length;

  const correct = (maxNumber === 1) || (maxNumber <= numberOfAnswersWithMaxDesirability && maxNumber >= 1);

  return correct ? null : { maxNumberTooHigh: true };
}

// it is not set anywhere jet
export function questionTextValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const question = control as FormGroup;
  const correct =
    question.get('text').value &&
    (question.get('answers') as FormArray).controls.every(
      (answers) => answers.get('text').value
    );

  return correct ? null : { noText: true };
}

export function answerLengthValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const question = control as FormGroup;
  const correct = (question.get('answers') as FormArray).length > 1;

  return correct ? null : { onlyOneAnswer: true };
}

export function quizModulesValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const orderOfModules = control.value as string[];

  return orderOfModules
    .every((module: string) => module === QUIZ_MODULES.KNOCKOUT_QUESTIONS || module === QUIZ_MODULES.INHOUSE_QUESTION)
      ? { onlyKnockoutOrInhouseQuestions: true }
      : null;
}

export function imageOrQuestionValidator(control: FormGroup): { [key: string]: boolean } | null {
  const question = control as FormGroup;
  const questionImage = question.get('image');
  const questionVideo = question.get('video');
  const questionText = question.get('text');

  const correct = questionImage.value || questionVideo.value || questionText.value;

  return correct ? null : { noImageOrText: true };
}
