import { PersonalityTest, Quiz, VideoQuestion, IJob, IJobPayload, TypeOfSkillsQuestion, Answer } from '../model/job.interface';
import { QUIZ_MODULES } from '../resources/quiz-modules';
import { SMS_CONTENT } from '../resources/sms-content';
import { Trigger } from '../model/trigger.interface';
import { Job } from './job.class';
import { IHiringStatus } from '../model/hiring-status.interface';
import { TranslationKeys } from '../model/translation-object.interface';

export class UniversalJob {

  businessQuestions: Quiz;
  id?: number;
  jobTitle: string;
  jobDescription: string;
  language: TranslationKeys;
  orderOfQuestionsModules: string[];
  personalityTest: PersonalityTest;
  introMessage: string;
  thankYouMainMessage: string;
  thankYouMessage: string;
  introMainMessage: string;
  videoQuestions: VideoQuestion;
  reminderSms: string;
  sendReminderSms: boolean;
  triggers: Trigger[];
  excludedJobTriggersHiringStatusIds: number[];
  contentButton: string;
  linkedinProfile: string;

  constructor(job: IJob, language: string) {
    this.id = job.id;
    this.jobTitle = job.jobTitle;
    this.jobDescription = job.jobDescription;
    this.language = job.language;
    this.contentButton = job.contentButton;
    this.personalityTest = {
      psykometrikaLanguage: job.psykometrikaLanguage
    };
    this.sendReminderSms = job.sendReminderSms;

    this.orderOfQuestionsModules = job.orderOfQuestionsModules;

    const { reminderSms, thankYouMessage, thankYouMainMessage, introMessage, introMainMessage } = SMS_CONTENT[language];

    this.thankYouMainMessage = job.thankYouMainMessage || thankYouMainMessage;
    this.thankYouMessage = job.thankYouMessage || thankYouMessage;
    this.introMainMessage = job.introMainMessage || introMainMessage;
    this.introMessage = job.introMessage || introMessage;
    this.reminderSms = job.reminderSms || reminderSms;
    this.linkedinProfile = job.linkedinProfile;

    if (job.videoQuestions) {
      this.videoQuestions = job.videoQuestions;
      this.videoQuestions.questions = Job.setVideoQuestions(job.videoQuestions);
    }

    if (job.businessQuestions) {
      this.businessQuestions = job.businessQuestions;
      this.businessQuestions.minPercentageForPass = job.businessQuestions.minPercentageForPass;
    }

    this.triggers = job.applicationHiringStatusTriggers || [];
    this.excludedJobTriggersHiringStatusIds = (job.applicationHiringStatusesWhoseTriggersAreIgnored as IHiringStatus[])
      .map(({id}: IHiringStatus) => id);
  }

  static prepareForPublish(job: UniversalJob, language: TranslationKeys): IJobPayload {
    const universalJob = {
      businessQuestions: null,
      videoQuestions: null
    } as IJobPayload;

    if (job.videoQuestions) {
      const videoQuestions = {
        video: job.videoQuestions.video || '',
        videoMainMessage: job.videoQuestions.videoMainMessage,
        videoMessage: job.videoQuestions.videoMessage
      } as VideoQuestion;

      job.videoQuestions.questions
        .forEach((question: string, index: number) => {
          const questionNumber = 'q' + (index + 1);
          videoQuestions[questionNumber] = question;
        });

      universalJob.videoQuestions = videoQuestions;
    }

    Object
      .keys(job)
      .forEach((controlName: string) => {
        if (
          controlName === 'excludedJobTriggersHiringStatusIds' ||
          controlName === 'triggers'                           ||
          controlName === QUIZ_MODULES.VIDEO_QUESTIONS
        ) {
          return;
        }

        if (controlName === QUIZ_MODULES.BUSINESS_QUESTIONS) {
          universalJob.businessQuestions = null;
          job[controlName].questions.forEach((question) => {
            if (!question.image) {
              delete question.image;
            }

            if (!question.video) {
              delete question.video;
            }

            if (!question.maxNumberOfAnswersCandidateCanChoose || question.maxNumberOfAnswersCandidateCanChoose === 1) {
              question.questionType = TypeOfSkillsQuestion.singleAnswerQuestion;
              delete question.maxNumberOfAnswersCandidateCanChoose;
              delete question.questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose;
            } else {
              question.questionType = TypeOfSkillsQuestion.multipleAnswersQuestion;
              question.questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose =
                !!question.questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose;
            }

            question.answers.forEach((answer) => {
              if (!answer.tag) {
                delete answer.tag;
              }

              answer.desirability = answer.desirability || 0;

              if (!question.maxNumberOfAnswersCandidateCanChoose && answer.followUpQuestion) {
                question.questionType = TypeOfSkillsQuestion.questionWithFollowUpQuestions;
                delete answer.desirability;
                delete answer.followUpQuestion.maxNumberOfAnswersCandidateCanChoose;
                delete answer.followUpQuestion.questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose;
                delete answer.followUpQuestion.questionType;
                if (!answer.followUpQuestion.image) {
                  delete answer.followUpQuestion.image;
                }

                if (!answer.followUpQuestion.video) {
                  delete answer.followUpQuestion.video;
                }

                answer.followUpQuestion.answers.forEach((followUpAnswer: Answer) => {
                  if (!followUpAnswer.tag) {
                    delete followUpAnswer.tag;
                  }

                  followUpAnswer.desirability = followUpAnswer.desirability || 0;
                });
              }
            });
          });
          universalJob.businessQuestions = job[controlName];
          return;
        }

        universalJob[controlName] = job[controlName];
    });

    universalJob.personalityTest = !!universalJob.personalityTest;

    if (job.personalityTest) {
      universalJob.psykometrikaLanguage = job.personalityTest.psykometrikaLanguage;
    }

    universalJob.applicationHiringStatusTriggers = Job.prepareTriggers(job.triggers);
    universalJob.applicationHiringStatusesWhoseTriggersAreIgnored = job.excludedJobTriggersHiringStatusIds;

    return universalJob;
  }
}
