import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Branch } from '../model/branch.interface';
import { Role, Roles } from '../model/role.interface';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import ICompany, {
       HomePage,
       IntroText,
       IntroTextPayload,
       TermsAndConditionsPayload,
       WhyWorkWithUs, WhyWorkWithUsSection } from '../model/company.interface';
import { JobLocation, IJob } from '../model/job.interface';
import { UniversalJob } from '../classes/universal-job.class';
import { JobsOverview } from '../model/jobs-overview.interface';
import { SetupService } from './setup.service';
import { Pagination } from '../model/pagination.interface';
import { Countries } from '../resources/countries';
import { Company } from '../classes/organization.class';
import { changeVideoExtension } from 'src/app/resources/video-url-transformation-functions';
import { ILibraryData } from '../model/library-data.interface';
import { TranslationKeys } from '../model/translation-object.interface';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {

  private _roles: Role[] = [
    { value: Roles.companyRM, name: 'Regional manager' },
    { value: Roles.recruiter, name: 'Recruiter' },
    { value: Roles.companyHR, name: 'HR' },
  ];
  private _refreshedBranches$: BehaviorSubject<Branch[]> = new BehaviorSubject<Branch[]>(null);
  private _refreshedAllBranches$: BehaviorSubject<Branch[]> = new BehaviorSubject<Branch[]>(null);
  private _locations: Map<Countries, JobLocation[]> = new Map<Countries, JobLocation[]>();

  constructor(
    private http: HttpClient,
    private setupService: SetupService
  ) {}

  get refreshedBranches$(): Observable<Branch[]> {
    return this._refreshedBranches$.asObservable();
  }

  get refreshedAllBranches$(): Observable<Branch[]> {
    return this._refreshedAllBranches$.asObservable();
  }

  get roles(): Role[] {
    return this._roles;
  }

  getCompanyBranches(searchQuery?: string): Observable<Branch[]> {
    let params = new HttpParams();

    params = params.append('limit', '1000');

    if (searchQuery) {
      params = params.append('searchBy', searchQuery);
    }
    return this.http
      .get(`${environment.companies}/${this.setupService.companyGuid}/branches`, {params})
      .pipe(
        map(({data}: Pagination<Branch>) => data)
      );
  }

  getUpdatedCompanyBranches(searchQuery?: string): Observable<Branch[]> {
    let params = new HttpParams();

    params = params.append('limit', '1000');

    if (searchQuery) {
      return this.http
        .get(`${environment.companies}/${this.setupService.companyGuid}/branches`, {params})
        .pipe(
          map(({data}: Pagination<Branch>) => data),
          tap((branches: Branch[]) => this._refreshedAllBranches$.next(branches)),
          switchMap(() => {
            params = params.append('searchBy', searchQuery);

            return this.http
              .get(`${environment.companies}/${this.setupService.companyGuid}/branches`, {params})
              .pipe(
                map(({data}: Pagination<Branch>) => data),
                tap((branches: Branch[]) => this._refreshedBranches$.next(branches))
              );
          })
        );
    }

    return this.http
      .get(`${environment.companies}/${this.setupService.companyGuid}/branches`, {params})
      .pipe(
        map(({data}: Pagination<Branch>) => data),
        tap((branches: Branch[]) => {
          this._refreshedAllBranches$.next(branches);
          this._refreshedBranches$.next(branches);
        })
      );
  }

  createBranch(
    name: string,
    location: string,
    searchQuery?: string
  ): Observable<Branch[]> {
    return this.http
      .post(
        `${environment.companyBranches}`,
        {
          name,
          location,
          company: this.setupService.companyId,
        }
      )
      .pipe(
        switchMap(() => this.getUpdatedCompanyBranches(searchQuery))
      );
  }

  editBranch(
    branchID: number,
    name: string,
    location: number,
    searchQuery?: string
  ): Observable<Branch[]> {
    return this.http
      .put(
        `${environment.companyBranches}/${branchID}`,
        { name, location }
      )
      .pipe(
        switchMap(() => this.getUpdatedCompanyBranches(searchQuery))
      );
  }

  getLocations(): Observable<JobLocation[]> {
    const countryId = this.setupService.countryId;
    const cachedLocations = this._locations.get(countryId);

    if (cachedLocations) {
      return of(cachedLocations);
    }

    let params = new HttpParams();
    params = params.append('country', `${countryId}`);

    return this.http
      .get(`${environment.locations}?limit=1000`, { params })
      .pipe(
        map(({data}: Pagination<JobLocation>) => data),
        map((locations: JobLocation[]) => {
          const { language } = this.setupService.currentCompany;
          const remoteIndex = locations
            .findIndex((location: JobLocation) => location.name[language] === 'Remote');

          if (remoteIndex > -1) {
            const remoteLocation = locations[remoteIndex];
            locations.splice(remoteIndex, 1);
            locations.unshift(remoteLocation);
          }

          return locations;
        }),
        tap((locations: JobLocation[]) => this._locations.set(countryId, locations)),
      );
  }

  getLocationsWithOutRemote(): Observable<JobLocation[]> {
    return this.getLocations()
      .pipe(
        map((locations: JobLocation[]) => {
          const { language } = this.setupService.currentCompany;
          return locations[0].name[language] === 'Remote' ? locations.slice(1, locations.length) : locations;
        })
      );
  }

  getCompanyInfo(): Observable<Company> {
    return this.http.get(`${environment.companies}/${this.setupService.companyId}`)
      .pipe(
        map(({data}: Pagination<ICompany>) => data[0]),
        map((companyValue: ICompany) => {
          const company = new Company(companyValue);
          this.setupService.currentCompany = company;
          this.setupService.setLogo = company.logo;
          this.setupService.setShowMarketplaceBanner = true;
          return company;
        })
      );
  }

  updateCompanyPageStatuses(
    homePageStatus: boolean,
    whyWorkWithUsStatus: boolean,
    expressInterestStatus: boolean,
    introText: boolean
  ): Observable<ICompany> {
    return this.http.put<Pagination<ICompany>>(
      `${environment.companies}/${this.setupService.companyId}`,
      {
        useCompanyLandingPage: homePageStatus,
        useWhyWorkWithUs: whyWorkWithUsStatus,
        useUniversalJob: expressInterestStatus,
        useCompanyIntro: introText
      }
    ).pipe(
      map(({data}: Pagination<ICompany>) => data[0]),
      tap((company: ICompany) => {
      this.setupService.currentCompany = new Company(company);
    })
    );
  }

  updateTermsAndConditions(termsAndConditions: TermsAndConditionsPayload): Observable<ICompany> {
    return this.http.put<ICompany>(
      `${environment.companies}/${this.setupService.companyId}`,
      termsAndConditions
    );
  }

  getCareerHomePage(companyId: number): Observable<HomePage> {
    return this.http.get<HomePage>(`${environment.companyHomePage}/${companyId}`);
  }

  updateCareerHomePage(payload: any): Observable<HomePage> {
    return this.http.post<HomePage>(`${environment.companies}/${this.setupService.companyGuid}/company_landing_pages`, payload);
  }

  getWhyWorkWithUs(pageId: number): Observable<WhyWorkWithUsSection[]> {
    return this.http
      .get<WhyWorkWithUs>(`${environment.companyWhyWorkWithUs}/${pageId}`)
      .pipe(
        map((wwwu: WhyWorkWithUs) => {
          const {sections, id, ...firstSection} = wwwu;

          return [firstSection].concat(sections || []);
        })
      );
  }

  updateWhyWorkWithUs(payload: any): Observable<WhyWorkWithUs> {
    return this.http.post<WhyWorkWithUs>(
      `${environment.companies}/${this.setupService.companyGuid}/why_work_with_us`,
      payload
    );
  }

  getIntroText(id: number): Observable<IntroText> {
    return this.http.get<IntroText>(`${environment.companyIntroText}/${id}`);
  }

  updateIntroText(payload: IntroTextPayload): Observable<IntroText> {
    return this.http.post<IntroText>(`${environment.companies}/${this.setupService.companyGuid}/company_intros`, payload);
  }

  getUniversalJobWithApplications(): Observable<JobsOverview> {
    let params = new HttpParams();
    const id = this.setupService.currentCompany.universalJob.id;
    params = params.append('withApplications', 'true');

    return this.http.get<JobsOverview>(`${environment.universalJobs}/${id}`, { params });
  }

  getUniversalJob(id: number): Observable<UniversalJob> {
    return this.http.get<IJob>(`${environment.universalJobs}/${id}`)
      .pipe(
        map(job => new UniversalJob(job, this.setupService.currentCompany.language))
      );
  }

  editUniversalJob(job: UniversalJob, language: TranslationKeys): Observable<IJob> {
    const universalJob = UniversalJob.prepareForPublish(job, language);

    return this.http.post<IJob>(`${environment.companies}/${this.setupService.companyGuid}/universal_jobs`, universalJob);
  }

  updateCompany(companyInformation: ICompany): Observable<Company> {
    const amsCompany = companyInformation.amsCompany;

    if (amsCompany) {
      amsCompany.amsNumber = amsCompany.amsNumber.trim();
      amsCompany.street = amsCompany.street.trim();
    }

    companyInformation.organizationNumber = companyInformation.organizationNumber.trim();
    companyInformation.vatNumber = companyInformation.vatNumber.trim();

    const logo = companyInformation.companyLogoEnc.split(',')[1];

    if (logo) {
      companyInformation.companyLogoEnc = logo;
    } else {
      delete companyInformation.companyLogoEnc;
    }

    return this.http.put(`${environment.companies}/${this.setupService.companyId}`, companyInformation)
      .pipe(
        switchMap(() => this.getCompanyInfo())
      );
  }

  getCompanyVideoLibrary(): Observable<ILibraryData[]> {
    return this.http
      .get<ILibraryData[]>(`${environment.companies}/${this.setupService.companyGuid}/job_videos`)
      .pipe(
        map((data) => {
          data.map(item => item.videoPoster = changeVideoExtension(item.video, 'jpg'));
          return data;
        })
      );
  }

  getStoryImageLibrary(): Observable<any> {
    return this.http
      .get<any>(`${environment.companies}/${this.setupService.companyGuid}/story_image_library`)
      .pipe(
        map((data) => {
          data.map(item => item.picture = item.imageUrl);
          return data;
        })
      );
  }

  getStoryVideoLibrary(): Observable<ILibraryData[]> {
    return this.http
      .get<ILibraryData[]>(`${environment.companies}/${this.setupService.companyGuid}/story_video_library`)
      .pipe(
        map((data) => {
          data.map(item => {
            item.video = item.videoUrl;
            item.videoPoster = changeVideoExtension(item.videoUrl, 'jpg');
          });
          return data;
        })
      );
  }

  getCompanyImageLibrary(): Observable<any> {
    return this.http
      .get<any>(`${environment.companies}/${this.setupService.companyGuid}/job_images`);
  }

  removeJobLibraryData(companyInformation: Partial<ICompany>): Observable<Company> {
    return this.http.put(`${environment.companies}/${this.setupService.companyId}`, companyInformation)
      .pipe(
        switchMap(() => this.getCompanyInfo())
      );
    }

  removeVideoStoryLibraryData(guid: string): Observable<ILibraryData[]> {
    return this.http.post<ILibraryData[]>(`${environment.removeStoryVideo}/${guid}`, null);
  }

  removeImageStoryLibraryData(guid: string): Observable<ILibraryData[]> {
    return this.http.post<ILibraryData[]>(`${environment.removeStoryImage}/${guid}`, null);
  }
}
